/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../components/Buttons";
import { graphql } from "gatsby";
import {
  FaCheck,
  FaTimes,
  FaMapMarkerAlt,
  FaSearch,
  FaPhone
} from "react-icons/fa";
import Layout from "../components/Layout";
import { ResponsiveWidthContainer } from "../components/Section";
import { Rating } from "../components/Rating";
import { CustomerReviewBox } from "../components/CustomerReviewBox";

const ResponsiveBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-contain: space-between;

  @media all and (min-width: 480px) {
    flex-flow: row nowrap;
  }

  > *:first-child {
    flex-grow: 1;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-contain: space-between;
`;

const ImageRoll = styled.div`
  display: flex;
  flex-flow: row wrap;
  * {
    margin-right: 1rem;
  }
`;

const CityList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: inline;
  }

  li:not(:last-child) {
    &:after {
      content: ", ";
    }
  }
`;

// const Row = styled.div`
//   display: flex;
//   flex-flow: row nowrap;
//   justify-content: space-between;
// `;

const YesOrNoMark = ({ check }) => {
  return check ? (
    <FaCheck
      css={`
        color: #6dd644;
      `}
    />
  ) : (
    <FaTimes
      css={`
        color: #fc3d45;
      `}
    />
  );
};

const checkListStyle = `
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  font-size: 1.5rem;
`;

const Heading3 = styled.h3`
  font-size: 2rem;
  font-weight: normal;
`;

const iconStyle = `
  font-size: 2rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
`;

const BoldUnderline = styled.span`
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 2px solid currentColor;
  display: inline-block;
`;

export const InstallerProfilePageTemplate = ({
  company_name,
  company_address,
  ratings,
  reviews,
  company_website,
  company_phone,
  services,
  company_accreditations,
  company_logo
}) => {
  return (
    <ResponsiveWidthContainer>
      <ResponsiveBox>
        <h2
          css={`
            font-size: 2.5rem;
            font-weight: normal;
          `}
        >
          {company_name} Reviews
        </h2>
        <Button color="secondary" outline>
          Review {company_name}
        </Button>
      </ResponsiveBox>
      <ResponsiveBox>
        <Column>
          <div
            css={`
              font-size: 2.5rem;
            `}
          >
            <Rating count={ratings.total} />
          </div>
          <div>
            Based on{" "}
            <BoldUnderline>
              {reviews.length} {reviews.length === 1 ? "review" : "reviews"}
            </BoldUnderline>
          </div>
        </Column>
        <Column>
          <ul
            css={`
              list-style-type: none;
              font-size: 2rem;
            `}
          >
            <li>
              <Rating count={ratings.industry_expertise} /> Industry Experience
            </li>
            <li>
              <Rating count={ratings.customer_service} /> Customer Service
            </li>
            <li>
              <Rating count={ratings.workmanship} /> Workmanship
            </li>
            <li>
              <Rating count={ratings.after_service_support} />
              After Service Support
            </li>
          </ul>
        </Column>
      </ResponsiveBox>
      <ResponsiveBox>
        <Column>
          <Heading3>About {company_name} (Solar Company)</Heading3>
          <address>
            <FaMapMarkerAlt css={iconStyle} />
            {company_address.address_full}, {company_address.address_country}
          </address>
          <div>
            <FaSearch css={iconStyle} />
            <a href={company_website}>{company_website}</a>
          </div>
          <div>
            <FaPhone css={iconStyle} />
            <a href={`tel:${company_phone}`}>{company_phone}</a>
          </div>
          <ul css={checkListStyle}>
            <li>
              <YesOrNoMark check={services.solar_power} /> Solar Power
            </li>
            <li>
              <YesOrNoMark check={services.solar_hot_water} /> Solar Hot Water
            </li>
            <li>
              <YesOrNoMark check={services.hybrid_solar} /> Hybrid Solar
            </li>
            <li>
              <YesOrNoMark check={services.off_grid_solar} /> Off Grid Solar
            </li>
            <li>
              <YesOrNoMark check={services.solar_maintenance} /> Solar
              Maintenane/Upgrades
            </li>
            <li>
              <YesOrNoMark check={services.commercial_solar_installations} />
              Commercial Solar Installations
            </li>
          </ul>
        </Column>
        <Column>
          {company_logo !== "" && (
            <img src="https://via.placeholder.com/400x200?text=Logo" />
          )}
          <br />
          {company_accreditations.CEC === true && (
            <img src="https://via.placeholder.com/400x200?text=CEC" />
          )}
        </Column>
      </ResponsiveBox>
      <Column>
        <p>
          Labore ea minim est amet tempor reprehenderit elit nisi nostrud. Minim
          quis esse culpa ut irure minim elit. Occaecat proident elit
          consectetur labore voluptate est. Veniam nisi id voluptate nostrud sit
          mollit non excepteur. Pariatur excepteur quis labore sint magna
          cupidatat anim aliqua sint pariatur. Quis nulla aliquip incididunt
          Lorem elit.
        </p>

        <p>
          Reprehenderit ex dolor cillum duis. Irure ad pariatur elit non. Duis
          est cupidatat consectetur duis elit esse incididunt qui nostrud do
          enim voluptate. Magna magna culpa magna eu fugiat proident occaecat
          duis eiusmod aliqua velit velit.
        </p>
      </Column>
      <Column>
        <Heading3>{company_name} Installations</Heading3>
        <ImageRoll>
          <img src="https://via.placeholder.com/140" />
          <img src="https://via.placeholder.com/140" />
          <img src="https://via.placeholder.com/140" />
        </ImageRoll>
      </Column>
      <Column>
        <Heading3>{company_name} Solar Service Locations</Heading3>
        <CityList>
          <li>Sydney</li>
          <li>Sydney</li>
          <li>Sydney</li>
          <li>Sydney</li>
          <li>Sydney</li>
          <li>Sydney</li>
          <li>Sydney</li>
          <li>Sydney</li>
        </CityList>
      </Column>
      <Column>
        <Heading3>{company_name} Customer Reviews</Heading3>
        {reviews.map(x => (
          <CustomerReviewBox
            key={`${x.review_name}${x.review_date}`}
            review={x}
          />
        ))}
      </Column>
    </ResponsiveWidthContainer>
  );
};

InstallerProfilePageTemplate.propTypes = {
  company_name: PropTypes.string.isRequired
};

const InstallerProfilePage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <InstallerProfilePageTemplate {...data.yourSolarCompany} />
    </Layout>
  );
};

InstallerProfilePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default InstallerProfilePage;

export const installerProfilePageQuery = graphql`
  query InstallerProfilePage($id: String!) {
    yourSolarCompany(id: { eq: $id }) {
      company_phone
      company_logo
      services {
        solar_power
        solar_hot_water
        hybrid_solar
        off_grid_solar
        solar_maintenance
        commercial_solar_installations
      }
      company_address {
        address_full
        address_country
      }
      reviews {
        review_rating
        review_date
        review_name
        review_suburb
        review_state
        review_content
      }
      company_website
      company_name
      company_accreditations {
        CEC
      }
      ratings {
        total
        industry_expertise
        customer_service
        workmanship
        after_service_support
      }
      objectID
    }
  }
`;
